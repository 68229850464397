.contact-sec1{
    width: 100%;
    background: url('..//..//images/water-treatment-process.gif');
    height: 50vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.contact-title{
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-title h1{
    font-size: 5rem;
    font-weight: 900;
    color: white;
    padding: 5px 10px 5px 10px;
    border-radius: 60px;
    background-color: rgba(0, 0, 0, 0.71);
}

.contact-area{
    background: linear-gradient(to bottom right,#97d3ef,#c7e7e9);
    width: 70%;
    margin: 30px auto 30px auto;
    display: flex;
    backdrop-filter: blur(10px);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.contact-form{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: solid 2px rgb(62, 169, 246);
}

input,textarea,button{
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
}

textarea{
    width: 60%;
    height: 10vh;
}

form{
    text-align: center;
    background: rgba(255, 255, 255, 0.504);
    backdrop-filter: blur(10px);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 40px;
    border-radius: 40px;
}

.contact-form form button:hover{
    background-color: rgb(62, 169, 246);
    cursor: pointer;
}

.contact-map{
    width: 50%;
    border-left: solid 2px rgb(62, 169, 246);
}

.cont1{
    width: 90%;
    margin: 10px auto 10px auto;
    background: rgba(255, 255, 255, 0.504);
    backdrop-filter: blur(10px);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 10px;
}

.cont1 ul li{
    list-style: none;
    margin-right: 15px;
    display: flex;
    color: rgb(71, 128, 243);
    font-size: 1.05rem;
    cursor: pointer;
    margin-top: 10px;
}

.cont1 ul li i{
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.cont2{
    width: 90%;
    margin: 20px auto 20px auto;
}

.cont2 h3 span{
    font-weight: 400;
}

.map-sec{
    width: 100%;
}

.cont2 h3{
    margin-bottom: 4px;
}

@media only screen and (max-width: 600px){

    .contact-area{
        display: block;
        width: 95%;
    }

    .contact-form{
        width: 90%;
        margin: auto;
        border-right: none;
    }

    .contact-form form{
        margin: 20px 0px 20px 0px;
    }

    .contact-map{
        border-left: none;
        width: 90%;
        margin: auto;
    }

    .map-sec{
        margin: 20px 0px 20px 0px;
    }

}



