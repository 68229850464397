/* .gallery-sec1{
    width: 100%;
    background: url('..//..//images/water-treatment-process.gif');
    height: 58.5vh;
    background-repeat: no-repeat;
    background-size: contain;
} */

.page-title{
    width: 100%;
    height: 58.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(1px);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.page-title h1{
    font-size: 3rem;
    font-weight: 900;
    color: black;
    /* padding: 5px 10px 5px 10px; */
    /* border-radius: 60px;
    background-color: rgba(0, 0, 0, 0.71); */
}

.g1 img{
    width: 15rem;
    height: 15rem;
}

.g1{
    margin: 10px;
    padding: 10px;
}

.gsec{
    display: flex;
    flex-wrap: wrap;
    width: 75%;
    margin: 30px auto 30px auto;
    justify-content: center;
}


@media only screen and (max-width: 600px){
    .g1 img{
        width: 20rem;
        height: 20rem;
    }
}